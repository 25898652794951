<template>
<div class="screeningJB">
  <div class="yyys">
    <img src="@/assets/screening/img/yyzc.png" alt="">
    <p @click="dy">免费预约复查</p>
  </div>
  <div class="yyys" @click="$router.push({path: '/screeningIntroduction'})" style="top: 320px"><p>筛查说明</p></div>
  <div class="bj"><div></div></div>
  <div class="box">
    <div class="title">
      <p>基本信息</p>
      <p v-if="AllData.HealthCheckId">报告编号：{{AllData.HealthCheckId.slice(0, 8)}}</p>
    </div>
    <div class="xxzx">
      <p>姓名：{{AllData.CustomerFull.Customer.CustomerName}}</p>
      <p>性别：{{AllData.CustomerFull.Customer.CustomerGender == 1 ? '男' : '女'}}</p>
      <p  v-if="showSr">年龄：{{nr}}</p>
    </div>
    <div class="ewmbox">
      <div>
        <div style="padding-bottom: 5px;" v-if="showSr">出生日期：{{AllData.CustomerFull.Customer.CustomerBirthday}}</div>
        <div>身份证号：{{AllData.CustomerFull.Customer.CustomerIDCardNo}}</div>
      </div>
      <div style="text-align: center;"  v-if="AllData.HealthCheckOppointmentId">
        <img src="../../assets/ewm.png" @click="ewm" alt="">
        <p>(预约二维码)</p>
      </div>
    </div>
  </div>
  <div class="box slsc">
    <div class="title">
      <p>视力筛查</p>
      <p>筛查时间：{{AllData.ListS_OP_All[0].S_OP_Time}}</p>
    </div>
    <ul>
      <li></li>
      <li>右眼(R)</li>
      <li>左眼(L)</li>
      <li>参考值</li>
    </ul>
    <ul class="nr">
      <li>远用裸眼视力</li>
      <li :class="AllData.S_OP_Screen.bDSCErrorOD ? 'cw' : ''">{{zhdl(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DSCOD)}}</li>
      <li :class="AllData.S_OP_Screen.bDSCErrorOS ? 'cw' : ''">{{zhdl(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DSCOS)}}</li>
      <li><span v-if="AllData.bShowRange">{{yybz}}</span></li>
    </ul>
    <ul class="nr">
      <li>远用矫正视力</li>
      <li :class="AllData.S_OP_Screen.bDVAErrorOD ? 'cw' : ''">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DVAOD}}</li>
      <li :class="AllData.S_OP_Screen.bDVAErrorOS ? 'cw' : ''">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DVAOS}}</li>
      <li><span v-if="AllData.bShowRange">{{yybz}}</span></li>
    </ul>
  </div>
  <div class="box slsc">
    <div class="title">
      <p>电脑验光</p>
      <p>筛查时间：{{AllData.ListS_OP_All[0].S_OP_Time}}</p>
    </div>
    <ul>
      <li></li>
      <li>右眼(R)</li>
      <li>左眼(L)</li>
      <li>参考值</li>
    </ul>
    <ul class="nr">
      <li>球镜(D)</li>
      <li :class="AllData.S_OP_Screen.bSPHErrorOD ? 'cw' : ''">{{qjzj(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DSOD)}}</li>
      <li :class="AllData.S_OP_Screen.bSPHErrorOS ? 'cw' : ''">{{qjzj(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DSOS)}}</li>
      <li><span v-if="AllData.bShowRange">{{qgbz}}</span></li>
    </ul>
    <ul class="nr">
      <li>柱镜(D)</li>
      <li :class="AllData.S_OP_Screen.bCYLErrorOD ? 'cw' : ''">{{qjzj(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DCOD)}}</li>
      <li :class="AllData.S_OP_Screen.bCYLErrorOS ? 'cw' : ''">{{qjzj(AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_DCOS)}}</li>
      <li><span v-if="AllData.bShowRange">0.00</span></li>
    </ul>
    <ul class="nr">
      <li>散光轴向</li>
      <li>{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_AxisOD}}</li>
      <li>{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_AxisOS}}</li>
      <li></li>
    </ul>
  </div>


  <div v-if="comid == '2AAE0CDF-B11E-46CC-839B-CE891ED9D04E'" class="box slsc">
    <div class="title">
      <p>眼生物测量</p>
      <p>筛查时间：{{AllData.ListS_OP_All[0].S_OP_Time}}</p>
    </div>
    <ul>
      <li></li>
      <li>右眼(R)</li>
      <li>左眼(L)</li>
      <li>参考值</li>
    </ul>
    <ul class="nr">
      <li>眼轴</li>
      <li>{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_AxisOculOD}}</li>
      <li>{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_AxisOculOS}}</li>
      <li><span ></span></li>
    </ul>
  </div>

  <div  class="box db" v-if="comid == 'ac867270-dcd7-4f53-b7de-d6a9f6b1346d' && AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination">
    <div class="title">
      <p>色觉检查</p>
    </div>
    <div  class="nr">
      <p>辩色：{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination == 1 ? '色觉检查无异常' : "色觉检查异常"}}</p>
      <p style="padding: 5px 0;" v-if="0">
        红：<span style="padding-right: 10px;">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination_Red == 1 ? '能辨识' : "不能辨识"}}</span>
        黄：<span style="padding-right: 10px;">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination_Yellow == 1 ? '能辨识' : "不能辨识"}}</span>
        绿：<span style="padding-right: 10px;">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination_Green == 1 ? '能辨识' : "不能辨识"}}</span><br>
        蓝：<span style="padding-right: 10px;">{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination_Blue == 1 ? '能辨识' : "不能辨识"}}</span>
        紫：<span>{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination_Purple == 1 ? '能辨识' : "不能辨识"}}</span>
      </p>
      <p v-if="AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorDiscrimination == 2">备注：{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_ColorWeakness}}</p>
    </div>
  </div>


  <div  class="box db">
    <div class="title">
      <p>医生建议</p>
    </div>
    <div  class="nr">{{AllData.CustomerFull.Customer.CustomerName}}同学：{{AllData.ListS_OP_All[0].S_OP_Json.S_OP_SC_Suggestion}}</div>
  </div>
  <div  class="box db">
    <div class="title">
      <p>温馨提示</p>
    </div>
    <div v-if="comid != 'ac867270-dcd7-4f53-b7de-d6a9f6b1346d'" class="nr">本次结果只作为筛查建议，不作为临床诊断依据，请点击右上角“免费预约复查”到复查点进行详细检查</div>
    <div v-if="comid == 'ac867270-dcd7-4f53-b7de-d6a9f6b1346d'" class="nr">
      本次筛查为初筛结果，在各校非暗室及非睫状肌麻痹（俗称“散瞳”）状态下进行，检查结果会因个人调节及光学环境等造成一定的影响，此结果仅供参考。出现结果显示异常时家长不必过份焦虑，只需到医疗机构进行复查，详情以确诊结果为准。
    </div>
<!--    <button @click="hqwz">获取位置</button>-->
  </div>

  <van-overlay :show="showdx" :z-index="99" style="display: flex;justify-content: center;align-items:center;background: rgba(0, 0, 0, 0.9)">
    <div v-if="!AllData.CustomerFull.Customer.CustomerTelCheckedTime" class="yzmbox">
      <p class="yzmt">请验证学校系统登记的家长手机号码可查看报告</p>
      <van-field
          style="width: 90vw;margin: 0 auto;"
          v-model="tel"
          required
          label="手机号"
          placeholder="请输入手机号"
      >
        <template #button>
          <van-button @click="getCode" v-if="miao == 60" size="small" style="width: 90px" type="primary">获取验证码</van-button>
          <van-button @click="getCode" size="small" style="width: 90px" v-if="miao != 60" type="warning">{{miao}}s</van-button>
        </template>
      </van-field>
      <van-field
          style="width: 90vw;margin: 0 auto;"
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button @click="bdTel" size="small"  style="width:90px" type="primary">验证</van-button>
        </template>
      </van-field>
    </div>
    <div class="yzmbox"  v-if="AllData.CustomerFull.Customer.CustomerTelCheckedTime">
      <p class="yzmt">请补充完整家长手机号码后四位，查看报告</p>
      <div class="bqhm" v-if="AllData.CustomerFull.Customer.CustomerTel">
        <span>号码：{{AllData.CustomerFull.Customer.CustomerTel.slice(0,7)}}</span>
        <input pattern="\d*"  ref="v1" inputmode="numeric" type="text" v-model="v1" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
        <input pattern="\d*"   ref="v2" inputmode="numeric" type="text"  v-model="v2" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
        <input pattern="\d*"  ref="v3" inputmode="numeric" type="text"  v-model="v3" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
        <input pattern="\d*" ref="v4" inputmode="numeric" type="text"  v-model="v4" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
        <van-button size="small" type="primary" @click="yzhsw">确定</van-button>
      </div>
    </div>
  </van-overlay>

  <van-overlay :show="showewm" :z-index="99" style="display: flex;justify-content: center;align-items:center;background: rgba(0, 0, 0, 0.7)" @click="showewm=false">
    <div class="ewms" @click.stop="" v-if="showewm">
      <div ref="code"></div>
      <p>请到预约的复查点出示该二维码进行检查</p>
    </div>
  </van-overlay>
</div>
</template>

<script>
import pf from "../../publicFn/pf";
import QRCode from "qrcode"
export default {
  name: "screeningJB",
  data() {
    return {
      AllData: {
        CustomerFull: {
          Customer: {}
        },
        ListS_OP_All:[{S_OP_Json: {}}],
        S_OP_Screen: {}
      },
      comid: '',
      showdx: false,
      tel: '',
      miao: 60,
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      sms: '',
      nr: '',
      showewm: false,
      jwd: '',
      showSr: true
    }
  },
  computed: {
    qgbz() {
      return pf.quguangbz(this.nr)
    },
    yybz() {
      return pf.lyslyybz(this.nr)
    },
  },
  watch: {
    'v1': function (n, o) {
      if (n !== '') {this.$refs.v2.focus()}
    },
    'v2': function (n, o) {
      if (n !== '') {this.$refs.v3.focus()}
    },
    'v3': function (n, o) {
      if (n !== '') {this.$refs.v4.focus()}
    },
    'v4': function (n, o) {
      if (n.length >= 2) {this.v4 = n[n.length-1]}
    }
  },
  created() {
    /*
    HealthCheck.HealthCheckConfirmState是否=“create”
是的话，调用HealthCheck_UpdateState，post：
{"token":{xxxxxx},
"o":{
"HealthCheckId":筛查Id,
"HealthCheckConfirmState":"reportviewed",
"HealthCheckExpertRejectDes":原值返回,
"HealthCheckConfirmExpertId":原值返回,
"HealthCheckConfirmTime":原值返回,
"HealthCheckConfirmExpertSignPath":原值返回
}
}
    * */

    // this.hqwz()
    // console.log(pf.lysl(1, 12))

    this.comid = this.$store.state.Base.parameters.CompanyId

    this.getAllData()
  },
  methods: {
    getAllData() {
      this._http.post('https://interface2.visumall.com/Customer/HealthCheck.ashx?cmd=Get', {
        "token": {
          "TimeSpan": 1584328935,
          "sToken": "CE83037A4CB13EBF5EE18246885E7719"
        },
        "SearchCondition": {
          HealthCheckId: this.$store.state.Base.parameters.HealthCheckId,
        },
        ReturnInfo: {
          ListS_OP_All: 1,
          "S_OP_Screen": 1,//加这句
          CustomerFull: {}
        }
      })
          .then(res => {
            // this.showdx = true
            console.log(res.data.GetResult.CustomerFull.Customer.CustomerTelCheckedTime)
            if (!this.$store.state.Screening.isYzTel && this.$store.state.Base.parameters.ish5) {
              this.showdx = true
            }else if (!res.data.GetResult.CustomerFull.Customer.CustomerTelCheckedTime && this.$store.state.Base.parameters.ish5) {
              this.showdx = true
            }

            if (res.data.GetResult) {


              if (res.data.GetResult.HealthCheckCompanyId  == "09f5b201-eca3-4e3e-8c58-c76d24ece2fc") {
                this.showSr = false
              }

              console.log(res.data.GetResult)

              if (res.data.GetResult.HealthCheckConfirmState == 'create') {
                this.createqg({
                  "HealthCheckExpertRejectDes":res.data.GetResult.HealthCheckExpertRejectDes,
                  "HealthCheckConfirmExpertId":res.data.GetResult.HealthCheckConfirmExpertId,
                  "HealthCheckConfirmTime":res.data.GetResult.HealthCheckConfirmTime,
                  "HealthCheckConfirmExpertSignPath":res.data.GetResult.HealthCheckConfirmExpertSignPath,
                  id: this.$store.state.Base.parameters.HealthCheckId
                })
              }

              if (res.data.GetResult.ListS_OP_All && res.data.GetResult.ListS_OP_All.length) {
                res.data.GetResult.ListS_OP_All[0].S_OP_Json = this._pf.decoding(res.data.GetResult.ListS_OP_All[0].S_OP_Json)
                this.AllData = res.data.GetResult
                // console.log(this.AllData.S_OP_Screen)
                // if (!this.AllData.CustomerFull.Customer.CustomerTel && this.$store.state.Base.parameters.ish5) {
                //   this.showdx = true
                // }
                this.nr = pf.getYear(this.AllData.CustomerFull.Customer.CustomerBirthday)
              }
            }
          })
    },
    dy() {
      this.$store.commit('Base/upUser', JSON.parse(JSON.stringify(this.AllData.CustomerFull.Customer)))
      if (this.AllData.HealthCheckShopId) {
        if (this.$store.state.Base.parameters.ish5 || this.$store.state.Base.parameters.pc) {
          this.$router.push({path: '/appointmentHome/reserveList', query: {shopId: this.AllData.HealthCheckShopId}})
        }else {
          wx.miniProgram.navigateTo({ url: "/pages/scheduling/scheduling?mid="+this.AllData.HealthCheckShopId})
        }
      }else {
        if (this.$store.state.Base.parameters.ish5 || this.$store.state.Base.parameters.pc) {
          this.$router.push({path: '/appointmentHome', query:{CompanyId: this.AllData.HealthCheckCompanyId}})
        }else {
          wx.miniProgram.navigateTo({ url: "/pages/Review/Review?type=1" })
        }
      }
    },

    showts() {
      if (this.comid == 'ac867270-dcd7-4f53-b7de-d6a9f6b1346d') {
        this.$dialog.alert({
          title: '提示',
          message: '本次筛查为初筛结果，在各校非暗室及非睫状肌麻痹（俗称“散瞳”）状态下进行，检查结果会因个人调节及光学环境等造成一定的影响，此结果仅供参考。出现结果显示异常时家长不必过份焦虑，只需到医疗机构进行复查，详情以确诊结果为准！',
          theme: 'round-button'
        })
      }
    },

    generateRandomCode() {
      // 生成一个0到999999之间的随机整数
      const randomCode = Math.floor(Math.random() * 1000000);

      // 将随机数转换为6位字符串，不足6位在前面补零
      const sixDigitCode = String(randomCode).padStart(6, '0');

      return sixDigitCode;
    },

    getCode() {

      if (this.miao != 60) {
        return this.$dialog.alert({message: '请等待'+this.miao+'后再次获取验证码', theme: 'round-button'})
      }

      let  timer = setInterval(() => {
        this.miao -= 1
        if (this.miao == 0) {
          clearInterval(timer)
          this.miao = 60
        }
      }, 1000)
      let ipyz =  /^1[3456789]\d{9}$/
      if (ipyz.test(this.tel)) {
        this.code = this.generateRandomCode()
        this._http2.post('https://interface2.visumall.com/OtherSystem/AliSMS.ashx?cmd=SendSms', {
          PhoneNumbers: this.tel,
          code: this.code
        })
            .then(res => {
              // console.log(res)
              if (res.HttpStatus == 200) {
                // this.$dialog.alert({message: '发送成功', theme: 'round-button'})
                this.$notify({ type: 'success', message: '发送成功' });
                // this.$toast.success('发送成功');
              }else {
                this.$dialog.alert({message: '发送失败，请稍后再试', theme: 'round-button'})
              }
            })
      }else {
        this.$dialog.alert({message: '请输入正确的手机号码', theme: 'round-button'})
      }
    },

    bdTel() {
      if (!this.code || !this.sms || this.code !== this.sms) {
        return this.$dialog.alert({message: '绑定失败，请输入正确的验证码', theme: 'round-button'})
      }
      this._http2.post('https://interface2.visumall.com/Customer/Customer.ashx?cmd=UpdateTel', {
        "token": {
          "TimeSpan": 1584328935,
          "sToken": "CE83037A4CB13EBF5EE18246885E7719"
        },
        "CustomerId": this.AllData.CustomerFull.Customer.CustomerId,
        "CustomerTel": this.tel,
        isCheck: 1
      })
          .then(res => {
            if (res.ReturnCode == 5000) {
              this.$store.commit('Screening/upIsYzTel', true)
              this.showdx = false
              this.$notify({ type: 'success', message: '绑定成功' });
              // this.showts()
            }else {
              this.$dialog.alert({message: '绑定失败请稍后再试', theme: 'round-button'})
            }
          })
    },

    yzhsw() {
      let str = this.v1 + this.v2 + this.v3 + this.v4
      if ( str && this.AllData.CustomerFull.Customer.CustomerTel.indexOf(str) > -1) {
        this.$store.commit('Screening/upIsYzTel', true)
        this.showdx = false
        // this.showts()
      }else {
        this.$dialog.alert({message: '号码后4位输入错误', theme: 'round-button'})
      }
    },

    zhdl(str) {
      if (str === '' || (!str && str != 0)) return ''
      str = str.toString()
      let item = str
      if (str.indexOf('.') < 0) {
        item = str + '.0'
      }
      return item
    },
    qjzj(str) {
      if (str === '' || (!str && str != 0)) return ''
      if (str == 0) return '0.00'
      let item = str.toString()
      if (item.indexOf('-') < 0 && item.indexOf('+') < 0) {
        item = "+" + item
      }
      return item
    },

    yysl(str) {
      return pf.lysl(str, this.nr)
    },
    qgd(key) {
      return pf.qujing(this.AllData.ListS_OP_All[0].S_OP_Json[key], this.nr)
    },

    zjpd(key) {
      let i = 0
      this.AllData.ListS_OP_All[0].S_OP_Json[key] !== '' && this.AllData.ListS_OP_All[0].S_OP_Json[key] ? i += 1 : ''
      typeof this.AllData.ListS_OP_All[0].S_OP_Json[key] != 'object' ? i += 1 : ''
      this.AllData.ListS_OP_All[0].S_OP_Json[key] != 0 ? i += 1 : ''


      if (i == 3) return true
      if (i != 3) return false
    },

    qtpd(key, min, max) {
      let i = 0
      this.AllData.ListS_OP_All[0].S_OP_Json[key] !== '' ? i += 1 : ''
      typeof this.AllData.ListS_OP_All[0].S_OP_Json[key] != 'object' ? i += 1 : ''
      this.AllData.ListS_OP_All[0].S_OP_Json[key] < min || this.AllData.ListS_OP_All[0].S_OP_Json[key] > max ? i += 1 : ''

      if (i == 3) return true
      if (i != 3) return false
    },

    createqg(data) {
      this._http.post('https://interface2.visumall.com/Customer/HealthCheck.ashx?cmd=UpdateState', {
        "token": {
          "TimeSpan": 1584328935,
          "sToken": "CE83037A4CB13EBF5EE18246885E7719"
        },
        o: {
          "HealthCheckId":data.id,
          "HealthCheckConfirmState":"reportviewed",
          "HealthCheckExpertRejectDes":data.HealthCheckExpertRejectDes,
          "HealthCheckConfirmExpertId":data.HealthCheckConfirmExpertId,
          "HealthCheckConfirmTime":data.HealthCheckConfirmTime,
          "HealthCheckConfirmExpertSignPath": data.HealthCheckConfirmExpertSignPath
        }
      })
    },

    async ewm() {
      this.showewm = true
      let dlm = this.AllData.CustomerFull.Customer.CustomerId
      // let tjm = dlm.slice(0, 4)
      // console.log(tjm,this.AllData.CustomerFull.Customer.CustomerNO)
      // tjm = tjm + this.AllData.CustomerFull.Customer.CustomerNO

      setTimeout(async ()=> {
        const qrcodeDiv = this.$refs.code;

        try {
          // 使用 qrcode.js 生成 QR Code
          const url = dlm; // 设置 QR Code 的内容
          const options = {
            width: 200, // 设置宽度
            height: 200, // 设置高度
          };

          // 使用 qrcode.js 提供的 toCanvas 或 toDataURL 方法生成 QR Code
          const canvas = await QRCode.toCanvas(url, options);

          // 渲染到 DOM 元素中
          qrcodeDiv.appendChild(canvas);
        } catch (error) {
          console.error('Error generating QR Code:', error);
        }
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.screeningJB {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  //background: #F2F2F2;
}
.bj {
  background: #3792FB;
  height: 60px;
  width: 100vw;
  position: absolute;
  z-index: -9;
  div {
    background: #F2F2F2;
    width: 100vw;
    height: calc(100vh - 60px);
    margin-top: 60px;
  }
}
.box {
  width: calc(92% - 28px);
  margin: 10px auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.27);
  border-radius: 7px;
  z-index: 9;
  padding: 10px 14px;
  font-size: 1.7vh;
  max-font-size: 15px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #BFBFBF;
    padding-bottom: 7px;
    font-size: 1.6vh;
    max-font-size: 16px;
    p:nth-child(1) {
      font-size: 1.9vh;
      max-font-size: 16px;
      font-weight: bold;
      color: #3792FB;
    }
  }
}
.xxzx {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-weight: 500;
}
.slsc {
  ul {
    display: flex;
    font-weight: 500;
    align-items: center;
    margin: 8px 0;
    li {
      min-width: 60px;
      height: 20px;
      margin: 0 2vw;
      text-align: center;
    }
    li:nth-child(1) {width: 8em;background: #FFFFFF;margin: 0;text-align: left;}
  }
  .nr {
    li {
      min-width: 60px;
      background: #F2F2F2;
      border-radius: 3px;
      //font-size: 1.5vh;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3vh;
    }
    li:nth-child(1) {
      width: 8em;
      background: #FFFFFF;
      margin: 0;
      font-size: 1.7vh;
      max-font-size: 15px;
      display: block;
    }
  }
}
.db {
  .nr {
    padding: 12px;
    background: #F2F2F2;
    border-radius: 3px;
    margin-top: 8px;
  }
}
.cw {
  background: #FF7676 !important;
  color: #FFFFFF;
}
.yyys {
  background: #56A2FF;
  opacity: 0.75;
  border-radius: 10px;
  font-size: 15px;
  width: 32px;
  padding: 7px 0;
  position: fixed;
  right: -2px;
  top: 110px;
  z-index: 10;
  img {
    display: block;
    width: 20px;
    height: 24px;
    margin: 0 auto;
  }
  p {
    font-weight: bold;
    color: #000000;
    width: 1em;
    margin: 0 auto;
    padding-top: 8px;
  }
}
.ewmbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 27px;
    height: 27px;
  }
  p {
    font-size: 10px;
  }
}
.ewms {
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  div {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
}
.yzmbox {
  width: 90vw;
  padding: 20px 0;
  background: #FFFFFF;
  border-radius: 10px;
  .yzmt {
    background: #FFFFFF;
    font-size: 15px;
    padding-left: 10px;
    padding-bottom: 15px;
    text-align: center;
  }
  .bqhm {
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    width: 1em;
    margin-right: 5px;
    padding: 0 8px;
    outline: none;
    //border-radius: 5px;
    border: none;
    border-bottom: 1px solid #909090;
  }
}
</style>
